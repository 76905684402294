// ==================================================
//  Movie Youtube iframe API
//  https://developers.google.com/youtube/iframe_api_reference?hl=ja#Playback_quality
//  playerVars: https://developers.google.com/youtube/player_parameters.html?playerVersion=HTML5&hl=ja
// ==================================================
const tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";
const firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
let ytPlayer;
function onYouTubeIframeAPIReady() {
  ytPlayer = new YT.Player("youtube", {
    // id="youtube"
    videoId: "rkvTFbywdqY", // 動画のアドレスの指定
    playerVars: {
      playsinline: 1, // インライン再生を行う
      autoplay: 1, // 自動再生を行う
      fs: 0, // 全画面表示ボタンを表示しない
      rel: 0, // 再生中の動画と同じチャンネルの関連動画を表示
      controls: 0, // プレーヤー コントロールを表示しない
      modestbranding: 1, // YouTubeロゴの非表示
      iv_load_policy: 3, // アノテーションの非表示
      start: 0, // 50秒後から動画がスタート
      disablekb: 1, // キーボード操作を無効
    },
    events: {
      onReady: onPlayerReady,
      onStateChange: onPlayerStateChange,
    },
  });
}
// ミュートにしてから再生する設定
function onPlayerReady(event) {
  event.target.mute();
  event.target.playVideo();
}
// ループ設定
function onPlayerStateChange(event) {
  if (event.data == YT.PlayerState.ENDED) {
    event.target.playVideo();
  }
  if (event.data == YT.PlayerState.PAUSED) {
    // 一時停止防止
    event.target.playVideo();
  }
}
// 操作防止用要素追加
const youtubeElement = document.getElementById("youtube");
let newElement = document.createElement("div");
newElement.classList.add("youtube-mask");
const addNewElement = youtubeElement.before(newElement);

// ==================================================
//  Move Train
// ==================================================
// Selector
const trainSelector = ".js-animation-train";

// GSAP Timeline
const animation_train = gsap.timeline({
  repeat: -1,
  delay: 0.6,
});

// GSAP
animation_train.to(trainSelector, {
  y: "325%",
  x: "336%",
  ease: "none",
  duration: 5,
  delay: 2.5,
});

// ==================================================
//  Move Paraglider
// ==================================================
// Selector
const paragliderSelector = ".js-animation-paraglider";

// GSAP Timeline
const animation_paraglider = gsap.timeline({
  repeat: -1,
  delay: 0.6,
});

// GSAP
animation_paraglider
  .to(paragliderSelector, {
    y: "75%",
    x: "30%",
    ease: "none",
    duration: 8,
    delay: 1.2,
  })
  .to(paragliderSelector, {
    y: "145%",
    x: "110%",
    ease: "none",
    duration: 10,
  })
  .to(paragliderSelector, {
    opacity: 0,
  });

// ==================================================
//  Move Bus 1
// ==================================================
// Selector
const car1Selector = ".js-animation-car-1";
const car2Selector = ".js-animation-car-2";
const car3Selector = ".js-animation-car-3";

// GSAP Set
gsap.set([car1Selector, car2Selector, car3Selector], {
  opacity: 0,
});

// GSAP Timeline
const animation_car = gsap.timeline({
  repeat: -1,
  delay: 0.6,
});

// GSAP
animation_car
  .to(car1Selector, {
    // 1
    opacity: 1,
    duration: 0.3,
  })
  .to(
    car1Selector,
    {
      y: "495%",
      x: "470%",
      ease: "none",
      duration: 8, // dev
    },
    "-=0.4"
  )
  .to(
    car1Selector,
    {
      opacity: 0,
      duration: 0.3,
    },
    "-=0.2"
  )
  .to(
    car2Selector,
    {
      // 2
      opacity: 1,
      duration: 0.3,
    },
    "-=0.2"
  )
  .to(
    car2Selector,
    {
      y: "885%",
      x: "-850%",
      ease: "none",
      duration: 12,
    },
    "-=0.3"
  )
  .to(
    car2Selector,
    {
      opacity: 0,
      duration: 0.3,
    },
    "-=0.2"
  )
  .to(
    car3Selector,
    {
      // 3
      opacity: 1,
      duration: 0.3,
    },
    "-=0.2"
  )
  .to(
    car3Selector,
    {
      y: "455%",
      x: "435%",
      ease: "none",
      duration: 8,
    },
    "-=0.3"
  )
  .to(
    car3Selector,
    {
      opacity: 0,
      duration: 0.3,
    },
    "-=0.2"
  );
